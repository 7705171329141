 /* @import './_mixins.scss'; */
/* @import './../components/DisplayCard/style.scss';
@import './../container/Results/style.scss'; */



*, *:before, *:after {
  box-sizing: border-box;
}
html{
height: 100%;
}

.dark{
  background-color: #1A1A1B;

}

#root{
  height: 100%;
}
body{
   height: 100%;
  
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pac-container{
/* //  margin-top: 50px; */
  z-index: 2000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.display-card .location-image {
  /* .location-image { */
    width: 250px;
    height: 200px;
  /* } */
}


.google-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2%;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.google-stars {
  width: 20px;
  height: 20px;
}

.google-stars-container-main {
  position: relative;
  right: 10px;
}

.yelp-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  flex-wrap: wrap;

}

.rating-picture {
  height: 18px;
  position: relative;

}

.yelp-rating {
  position: relative;
  bottom: 2px;

}

.health-container {
  margin-top: 4%;
}

.health-data-container {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.google-btns {
  display: flex;
  width: 80%;
  margin-top: 5px;
   /* justify-content: space-evenly; */

  justify-content: space-between;
}

.yelp-btns {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-top: 5px;
}

.yelp-ratings-container{
    width: 80%;
    display: flex;
    justify-content: space-between;
}





/* 
results */

.card-container {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 2px;

 
}
@media only screen and (max-width: 500px) {
.card-container{
margin-top: 10px;
}
 

}

.card-container > div {

  margin: 15px
}